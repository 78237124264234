@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

#building_modal::-webkit-scrollbar {
  display: none;
}

.gm-style .gm-style-iw-c {
  top: -45px !important;
}

.gm-style .gm-style-iw-t::after {
  top: -45px !important;
}

.layout__header {
  height: 6rem;
  padding: 1rem;
}

.layout__header__content {
  display: flex;
  justify-content: flex-end;
}

.layout__content {
  min-height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 0 1rem;
}

.layout__footer {
  height: 4rem;
}


.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
}

.waviy span {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));

}

@keyframes waviy {

  0%,
  40%,
  100% {
    transform: translateY(0)
  }

  20% {
    transform: translateY(-20px)
  }
}